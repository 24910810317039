<template>
  <ex-breadcrumb>
    <div class="flex">
      <ex-breadcrumb-item v-for="item in crumbs()" :key="item.link">
        <router-link
          class="flex max-w-40 lg:max-w-xs 2.5xl:max-w-lg 4xl:max-w-4xl line-clamp-1"
          :to="item.link"
          aria-current="page"
        >
          {{ item.text }}
        </router-link>
      </ex-breadcrumb-item>
    </div>
  </ex-breadcrumb>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import apiStore from '../../stores/apiStore';
import applicationStore from '../../stores/applicationStore';
import Breadcrumb from '../../types/app/Breadcrumb';
import userStore from '../../stores/UserStore';
import apiProductStore from '../../stores/apiProductStore';

export default defineComponent({
  setup() {
    const route = useRoute();
    const aStore = apiStore();
    const productStore = apiProductStore();
    const appStore = applicationStore();
    const uSTore = userStore();
    let currentRoute = '';

    function getTo(path: string): string {
      currentRoute += `/${path}`;
      return currentRoute;
    }

    /**
     * This function loads the name of the entity from the current path, alternatively from “meta”.
     * The breadcrumb name from meta in the router must match the switch.
     * @param idx
     * @param path
     */
    function loadNameFromEntity(idx: number, path: string): string {
      if (idx - 1 >= 0 && route.matched[idx - 1] && route.matched[idx - 1].meta.isEntity) {
        const name = route.matched[idx - 1].name ?? route.matched[idx - 1].meta.BreadcrumbName;

        switch (name) {
          case 'APIs': {
            const api = aStore.getAPIByID(parseInt(path, 10));
            return api ? api.displayName : 'Not loaded yet';
          }
          case 'Applications': {
            const application = appStore.getApplicationByDI(parseInt(path, 10));
            return application ? application.displayName : 'Not loaded yet';
          }
          case 'Users': {
            const user = uSTore.getUserByDI(parseInt(path, 10));
            return user ? `${user.firstName} ${user.lastName}` : 'Not loaded yet';
          }
          case 'API Products': {
            const apiProduct = productStore.getApiProductByDI(parseInt(path, 10));
            return apiProduct ? apiProduct.displayName : 'Not loaded yet';
          }
          default: {
            console.log(`name ${name} not found`);
            return 'Case in BreadcrumbList.vue missing';
          }
        }
      }

      if (route.matched[idx] && route.matched[idx].meta.BreadcrumbName) {
        return String(route.matched[idx].meta.BreadcrumbName);
      }
      return path.charAt(0).toUpperCase() + path.slice(1);
    }

    function crumbs() {
      currentRoute = '';
      const pathArray = route.path.split('/');
      pathArray.shift();

      const breadcrumbs: Array<Breadcrumb> = pathArray.reduce(
        (breadcrumbArray: Array<Breadcrumb>, path: string, idx: number) => {
          breadcrumbArray.push({
            link: getTo(path),
            text: loadNameFromEntity(idx, decodeURI(path)),
          } as Breadcrumb);
          return breadcrumbArray;
        },
        [],
      );

      return breadcrumbs;
    }

    return { crumbs };
  },
});
</script>
