import { defineStore } from 'pinia';
import Api from '../types/apis/Api';

const apiStore = defineStore({
  id: 'api',
  state: () => ({
    apis: [] as Api[],
    images: [] as { apiId: number; image: string }[],
  }),
  getters: {
    getAPIs(): Api[] {
      return this.apis;
    },
  },
  actions: {
    getAPIByID(apiId: number): Api | undefined {
      return this.apis.find((apiInArray: Api) => Number(apiInArray.id) === Number(apiId));
    },
    getImage(apiId: number): string | undefined {
      const apiImage: { apiId: number; image: string } | undefined = this.images.find(
        (aI: { apiId: number; image: string }) => Number(aI.apiId) === Number(apiId),
      );
      if (apiImage) {
        return apiImage.image;
      }
      return undefined;
    },
    storeOrUpdateImage(apiId: number, image: string): void {
      let storeIt = true;
      this.images.map((aI: { apiId: number; image: string }) => {
        if (Number(aI.apiId) === Number(apiId)) {
          aI.image = image;
          storeIt = false;
        }
        return aI;
      });
      if (storeIt) {
        this.images.push({ apiId, image });
      }
    },
  },
});

export default apiStore;
