<template>
  <div
    v-if="isGlobalAdmin"
    class="menu-link flex items-center p-2 rounded-lg hover:bg-primary hover:text-secondary cursor-pointer"
    @click="store.cmsGlobalEditMode = !store.cmsGlobalEditMode"
  >
    <ex-icon
      v-if="store.cmsGlobalEditMode"
      icon="Create"
      label="Edit Mode"
      class="h-6 w-6"
      aria-hidden="true"
      style="display: inline-block; font-size: var(--exo-font-size-3x-large)"
    />
    <ex-icon
      v-else
      icon="Edit"
      label="Edit Mode"
      class="h-6 w-6"
      aria-hidden="true"
      style="display: inline-block; font-size: var(--exo-font-size-3x-large)"
    />
    <span v-if="lvl1BarOpen" class="pl-2 text-nowrap">Edit Mode</span>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUpdated, ref } from 'vue';
import RoleGuards from '../../routers/guards/RoleGuards';
import mainStore from '../../stores/MainStore';

defineProps({
  lvl1BarOpen: {
    type: Boolean,
    required: true,
  },
});

const isGlobalAdmin = ref(false);
const store = mainStore();

async function loadRole() {
  isGlobalAdmin.value = RoleGuards.isGlobalAdmin();
}

onMounted(async () => {
  await loadRole();
});

onUpdated(async () => {
  await loadRole();
});
</script>
